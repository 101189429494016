.container {
	display: flex;
	flex-direction: row;
	/* height: 60px; */
	justify-content: center;
	align-items: center;
	background-color: #f0f1f2;
	padding-right: 75px;
	padding-left: 75px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.pocaLogo {
	width: 71px;
	padding: 5px 0;
}

.euLogo {
	width: 40px;
}

.isLogo {
	width: 42px;
}

.guvRoLogo {
	width: 40px;
}

.logoItems {
	margin: auto;
  	display: block;
}

.headerV1 {
	margin: 0!important;
    /* display: table; */
    width: 100%;
    /* table-layout: fixed; */
	height: 40px;
}

.buttonsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo {
	height: 22px;
	margin-right: 20px;
}

.lang {
	margin-right: 20px;
}
