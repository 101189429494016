body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: "PoppinsLight";
	src: local("PoppinsLight"),
	 url("./fonts/Poppins/Poppins-Light.ttf") format("truetype");
	/* font-weight: bold; */
}

@font-face {
	font-family: "PoppinsBold";
	src: local("PoppinsBold"),
	 url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "PoppinsRegular";
	src: local("PoppinsRegular"),
	 url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
	/* font-weight: bold; */
}

svg {
	fill: currentColor;
  }