.container{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
    padding: 30px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.title{
    color: #333333;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 2px!important;
}