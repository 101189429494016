.container{
    position:fixed;
    top:0;
    left:0;
    background-color: #d3d3d3bb;
    width:100vw;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
}

.childrenWrapper{
    background-color: white;
    padding: 50px;
    border-radius: 20px;
}