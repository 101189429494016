.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: 100vh;
	text-align: center;
    background-color: #f0f1f2;
	padding: 30px 75px 15px;
}

.footerText {
	color: #003399;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
	font-size: 12px;
}