.eusurveyFrame {
	display: flex;
	flex: 1;
}

.iconEvaluareaServiciilor {
	width: 135px !important;
	height: 97px;
}

.boxTitleV1 {
	text-align: center;
	font: normal normal bold 18px/21px PoppinsBold;
	letter-spacing: 0px;
	color: #333333;
	min-height: 105px;
}

.contentContainer {
	background-color: white;
	padding: 36px;
}

.boxSubtitleV1 {
	text-align: center;
	font: normal normal bold 18px/21px PoppinsBold;
	letter-spacing: 0px;
	color: #333333;
}

.boxH2V2 {
	text-align: left;
	font: normal normal bold 32px/38px PoppinsBold;
	letter-spacing: 0px;
	color: #006AB3;
}

.boxPV2 {
	text-align: left;
	font: normal normal normal 18px/21px PoppinsRegular;
	letter-spacing: 0px;
	color: #FFFFFF;
}

.boxV2 {
	background: #83C2EB 0% 0% no-repeat padding-box;
	position: relative;
	z-index: 1;
	box-sizing: border-box;
	border: 20px solid transparent;
	background-clip: padding-box;
	padding: 66px 20px 20px 20px;
}

.boxV1 {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-sizing: border-box;
	border: 20px solid transparent;
	background-clip: padding-box;
	padding: 20px;
}

.boxLinkV1 {
	display: block;
	text-align: center;
	text-decoration: underline;
	font: normal normal 300 14px/16px PoppinsRegular;
	letter-spacing: 0px;
	color: #006AB3;
	opacity: 1;
	padding: 10px 0 20px 0;
}

.boxV1ImgRating {
	width: 100%;
	display: block;
	margin: 0 auto;
}




.completedNumber {
	text-align: center;
	font: normal normal bold 44px/52px PoppinsBold;
	letter-spacing: 0px;
	color: #333333;
}

.completedText {
	text-align: center;
	font: normal normal normal 18px/21px PoppinsRegular;
	letter-spacing: 0px;
	color: #9DA4AA;
}

.completedNumberBox {
	text-align: center;
	font: normal normal bold 18px/21px PoppinsBold;
	letter-spacing: 0px;
	color: #333333;
}

.completedTextBox {
	text-align: center;
	font: normal normal 300 14px/16px PoppinsRegular;
	letter-spacing: 0px;
	color: #9DA4AA;
}

.chestionareCompletedNumber {
	text-align: center;
	font: normal normal bold 32px/38px PoppinsBold;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
}

.chestionareCompletedText {
	text-align: center;
	font: normal normal normal 14px/16px PoppinsRegular;
	letter-spacing: 0px;
	color: #9DA4AA;
	opacity: 1;
}

.chestionareDepartamentText {
	text-align: left;
	font: normal normal bold 18px/21px PoppinsBold;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
	margin: 35px 0;
}

.reviewClientBoxText {
	text-align: left;
	font: normal normal 300 12px/14px PoppinsRegular;
	letter-spacing: 0px;
	color: #9DA4AA;
	padding: 20px 0 5px 0;
}

.reviewClientBoxTitle {
	text-align: left;
	font: normal normal bold 14px/16px PoppinsBold;
	letter-spacing: 0px;
	color: #333333;
	margin-bottom: 5px;
}

.reviewClientBox {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-sizing: border-box;
	border: 20px solid transparent;
	background-clip: padding-box;
	padding: 0px 25px;
}

.reviewClientImgRating {
	width: 65%;
	margin-bottom: 10px;
}

.departamentChestionareBox {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-sizing: border-box;
	border: 20px solid transparent;
	background-clip: padding-box;
	padding: 15px 25px 0px;
}


.imgOverBox {
	position: absolute;
	top: -47px;
	left: 0px;
	right: 0px;
	margin-left: auto;
	margin-right: auto;
	z-index: 2;
}

.imgProfileReview {
	width: 26px;
	height: 29px;
	margin: 0 20px 0 0;
}

.divProfileReview {
	height: 100%;
	float: left;
	margin: 20px 0;
}

.infoProfileReview {
	margin: 20px;
}

.filterButtonSpace {
	box-sizing: border-box;
	border: 8px solid transparent;
	background-clip: padding-box;
}

.filterButton {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #006ab3;
	border: 1px solid #003f88;
	box-shadow: 0px 3px 6px #00000029;
	padding: 12px;
}

.showMoreButton {
	background-color: #006ab3 !important;
	border: 1px solid #003f88 !important;
	width: 25% !important;
	margin: 0px 25px !important;
}

.filterButtonText {
	color: white;
	font-size: 14px;
}

.headerContainer {
	display: flex;
	margin-bottom: 60px;
}

.header {
	color: #006ab3;
	text-align: left;
	font: normal normal bold 24px/40px PoppinsBold;
	letter-spacing: 0px;
}

.subHeader {
	text-align: left;
	font: normal normal normal 18px/21px PoppinsRegular;
	letter-spacing: 0px;
	color: #9DA4AA;
}

.loader {
	border: 16px solid #f3f3f3;
	border-top: 16px solid #3498db;
	margin: 0 auto;
	top: 40%;
    position: absolute;
    left: 44%;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media only screen and (max-width: 991px) {

	.boxV1ImgRating {
		width: 50%;
	}

	.boxTitleV1 {
		min-height: 40px;
	}

	.chestionareDepartamentText {
		text-align: center;
	}
}

.formSelectStyle1 {
	background-color: transparent !important;
	border-radius: 0 !important;
	border: 0 !important;
	border-bottom: 1px solid #D9DADB !important;
	margin: 0px 0px 0 0 !important;
	background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
}