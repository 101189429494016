.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: 100vh;
}

.imageContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.logo {
	width: 6rem;
	height: auto;
}

.linksContainer {
	display: flex;
	flex-direction: column;
	flex: 2;
}

.linkRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.linkImage {
	width: 18px;
	height: auto;
	margin-right: 10px;
}

.linkText {
	color: #333333;
	font-size: 14px;
	text-decoration: none;
}

/* Sidebar */
.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	padding: 58px 0 0; /* Height of navbar */
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
	width: 240px;
	z-index: 600;
  }
  
  @media (max-width: 991.98px) {
	.sidebar {
	  width: 100%;
	}
  }