.container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-top: 75px;
	padding-bottom: 75px;
	padding-left: 50px;
	padding-right: 75px;
	background-color: #f7f8f8;
}

.contentContainer {
	padding: 36px;
	padding-left: 0px;
}

.row {
	display: flex;
	flex-direction: row;
}

.column {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.inputsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.inputContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	flex: 1;
}

.input {
	margin-left: 25px;
	-webkit-appearance: none;
	border: 0;
	outline: 0;
	outline: none !important;
	border-bottom: 1px solid #d9dadb;
	display: flex;
	flex: 1;
	font-size: 14px;
	background-color: #f7f8f8;
	margin-right: 40px;
}

.input::-ms-reveal,
input::-ms-clear {
display: none;
}

.headerContainer {
	display: flex;
	margin-bottom: 60px;
}

.header {
	font-size: 32px;
	color: #006ab3;
	font-weight: bold;
}

.subHeader {
	font-size: 18px;
	color: #9da4aa;
}

.headerTextsContainer {
	display: flex;
	flex-direction: column;
}

.headerIcon {
	width: 60px;
	margin-right: 20px;
}

.forgotPasswordContainer {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.forgotPassword {
	font-size: 14px;
	color: #006ab3;
}

.loginButtonContainer {
	display: flex;
}

.loginButton {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #006ab3;
	border: 1px solid #003f88;
	box-shadow: 0px 3px 6px #00000029;
	padding: 22px;
	padding-left: 90px;
	padding-right: 90px;
}

.loginButtonText {
	color: white;
	font-size: 14px;
}

.termsAndConditionsContainer {
	display: flex;
	margin-top: 16px;
}

.termsAndConditionsText {
	font-size: 12px;
}

.smallIcon{
	width: 15px;
}

.filterContainer{
	display: flex;
	flex-direction: row;
}