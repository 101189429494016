.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-height: 100vh;
}

.imageContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}

.logo {
	width: 6rem;
	height: auto;
}

.linksContainer {
	display: flex;
	flex-direction: column;
	flex: 2;
}

.linkRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}

.linkImage {
	width: 18px;
	height: auto;
	margin-right: 10px;
}

.linkText {
	color: #333333;
	font-size: 14px;
	text-decoration: none;
}

.linkTextActive {
	color: #006AB3;
	font-size: 14px;
	text-decoration: none;
}

