.container {
	display: flex;
	position: relative;
	flex-direction: column;
	flex: 1;
	max-height: 100vh;
	text-align: center;
	background-color: #ffffff;
	height: 46px;
	padding: 10px 75px 10px;
	border-bottom: 2px solid #D9DADB;
}

.logoReghinNavbar {
	width: 44px;
	height: 53px;
}

.buttonMeniuNavbar {
	float: right;
}

.iconMeniuNavbar {
	width: 24px;
	height: 26px;
}

.navItemLink {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 15px 0px;
}

.linkImage {
	width: 18px;
	height: auto;
	margin-right: 10px;
}

.linkText {
	color: #333333;
	font-size: 14px;
	text-decoration: none;
}

.linkText:hover {
	color: #006AB3;
	font-size: 14px;
	text-decoration: none;
}