.container{
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: white;
}

.title{
    color: #333333;
    font-size: 18px;
}

.backgroundLinie{
    width: 100%;
    background-color: #D9DADB;
    height: 5px;
}

.row{
    display:flex;
    flex:1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
